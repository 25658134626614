import React from 'react';
import Seo from '../components/Seo';
import DeepDiveLogo from '../components/DeepDiveLogo';
import Footer from '../components/Footer';

const Terms = () => (
    <>
        <Seo title="DeepDive 360 - Terms of Use" />
        <header>
            <div className='container'>
                <div className="row justify-content-center">
                    <div className="logo">
                        <DeepDiveLogo />
                    </div>
                </div>
                <div className="row justify-content-center">
                    <h1>Terms of Use</h1>
                </div>
            </div>
        </header>
        <main>
            <section>
                <h2>Revised as of Jan 15, 2018</h2>
                <p>The DeepDive360 service (collectively, "the Service") is operated by SkillShark Software Inc. ("us", "we" or "the Company"). By accessing or using our web site at DeepDive360.com or the mobile version applications thereof (together the "Site"), you (the "User") signify that you have read, understand and agree to be bound by these Terms of Use ("Terms of Use" or "Agreement"), whether or not you are a registered member of the Service.</p>
                <p>We reserve the right, at our sole discretion, to change, modify, add, or delete portions of these Terms of Use at any time without further notice. If we do this, we will post the changes to these Terms of Use on this page and will indicate at the top of this page the date these terms were last revised. Your continued use of the Service or the Site after any such changes constitutes your acceptance of the new Terms of Use. If you do not agree to abide by these or any future Terms of Use, do not use or access (or continue to use or access) the Service or the Site. It is your responsibility to regularly check the Site to determine if there have been changes to these Terms of Use and to review such changes.</p>
                <p>PLEASE READ THESE TERMS OF USE CAREFULLY AS THEY CONTAIN IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS.</p>
            </section>
            <section>
                <h2>Registration Data; Account Security</h2>
                <p>In consideration of your use of the Site, you agree to:</p>
                <ol type='a'>
                    <li>provide accurate, current and complete information about you as may be prompted by any registration forms on the Site ("Registration Data")</li>    
                    <li>maintain the security of your password and identification</li>
                    <li>maintain and promptly update the Registration Data, and any other information you provide to the Company, to keep it accurate, current and complete</li>
                    <li> be fully responsible for all use of your account and for any actions that take place using your account.</li>
                </ol>
                <br/>
                <p>You acknowledge and agree that information about you and/or any data that you submit to the Site may be viewed by third parties and that certain information submitted and/or uploaded to the Site, including but not limited to completion of an employees information, is intended to be viewed and used by other Users, and information submitted to the Site or provided by you to other Users directly may be shared by these other Users. In submitting information to be included with or related to an employee’s information you are giving the Company permission to store and share that information. The Company makes no representations about how the information will be used by other Users or third parties and is not responsible for the actions of others in keeping your information private.</p>
                <p>You also acknowledge and agree that in using the Site you are solely responsible for the accuracy of the information you submit and/or post, and that in doing so you are aware of what information is visible to and/or accessible by third parties, and other Users of the Site.</p>
                <p>In using the Site you assume all risks associated with dealing with other Users of the Site who access your information and/or with whom you come into contact.</p>
            </section>
            <section>
                <h2>Company Logo Art Submitted</h2>
                <p>Any company logos submitted by members may be displayed on our website.  If any member does not wish to have their art displayed simply contact DeepDive360 Support and we will remove from display in a timely fashion.  All logos submitted by users representing their respective groups are not the property of SkillShark Software Inc.</p>
            </section>
            <section>
                <h2>Proprietary Rights in Site Content; Limited License</h2>
                <p>All content on the Site (including all data provided by you) and available through the Service (the "Site Content") is the proprietary property of the Company with all rights reserved. No Site Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, scraped, displayed, posted, transmitted, or sold in any form or by any means, in whole or in part, without the Company's prior written permission.</p>
                <p>Provided that you are eligible for use of the Site, you are granted a limited license to access and use the Site and the Site Content and to download or print a copy of any Site Content to which you have properly gained access solely for your personal, non-commercial use. You may not upload or republish Site Content on any Internet, Intranet or Extranet site or incorporate the information in any other database or compilation, and any other use of the Site Content is strictly prohibited. Such license is subject to these Terms of Use and does not permit use of any data mining, robots, scraping or similar data gathering or extraction methods.</p>
                <p>Any use of the Site or the Site Content other than as specifically authorized herein, without the prior written permission of the Company, is strictly prohibited and will terminate the license granted herein. Such unauthorized use may also violate applicable laws including copyright and trademark laws and applicable communications regulations and statutes. Unless explicitly stated herein, nothing in these Terms of Use shall be construed as conferring any license to intellectual property rights, whether by estoppel, implication or otherwise. This license is revocable at any time without notice and with or without cause.</p>
            </section>
            <section>
                <h2>Payment Terms</h2>
                <p>Any fees to register to access any of our Services shall be paid through third party payment systems utilized by us, such as Stripe. Any fees paid for our Services are non-refundable, regardless of whether or not the User actually uses the Site or the Services. Users are responsible for paying all applicable taxes for the Services and any other costs incurred in connection with the use of or access to the Site.</p>
            </section>
            <section>
                <h2>Cancellation and Refund Policy</h2>
                <p>You may cancel a recurring subscription at any time by logging into your account and choosing to cancel. Once you cancel you won’t be charged again, but you are responsible for whatever charges have already been incurred for the current billing period. You will have a “Pending Subscription Change” noted on your account and you will continue to have the same access and benefits of DeepDive360 for the remainder of the current billing period.</p>
                <p>All in-app subscription purchases made on the iTunes App Store are subject to Apple’s <a target='_blank' rel="noreferrer" href='https://www.apple.com/legal/internet-services/itunes/us/terms.html'>terms and conditions</a>.</p>
                <p>All in-app subscription purchases made on the Google Play Store are subject to Google’s <a target='_blank' rel="noreferrer" href='https://play.google.com/intl/en-us_us/about/play-terms/index.html'>terms and conditions</a>.</p>
                <p>One-time purchases cannot be cancelled and are non-refundable. We reserve the right to make changes to our products at any time. If we temporarily reduce or eliminate the charge for content or access that you are currently paying for under different terms, you will not be entitled to a refund. If any or all of our digital products are temporarily unavailable, you will not receive a refund. We reserve the right to suspend or terminate your subscription or product for any reason, with or without notice and without further obligation. You will not be entitled to a refund in these circumstances. If any or all of our digital products are temporarily unavailable, you will not be entitled to a refund. In all cases we reserve the right to issue refunds or credits at our sole discretion. If we issue a refund or credit, we are under no obligation to issue the same or similar refund in the future.</p>
            </section>
            <section>
                <h2>Trademarks</h2>
                <p>DeepDive360 and other Company graphics, logos, designs, page headers, button icons, scripts and service names are trademarks or trade dress of the Company in Canada and the U.S. and/or other countries. The Company's trademarks and trade dress may not be used, including as part of trademarks and/or as part of domain names, in connection with any product or service in any manner that is likely to cause confusion and may not be copied, imitated, or used, in whole or in part, without the prior written permission of the Company.</p>
            </section>
            <section>
                <h2>Users</h2>
                <p>All persons who access and/or use the Site must be over the age of majority in the province, state, or territory in which they reside, or if under the age of majority, must have the prior permission of a parent or legal guardian to use the Site. By using the Site Users are confirming that they are over the age of majority or if under the age of majority, that they are using the Site with the consent and under the supervision of a parent or legal guardian. The Company reserves the right to require proof of consent for underage Users and if it is discovered that a User is underage and using the Site without permission the Company reserves the right to suspend or terminate use of the Site.</p>
            </section>
            <section>
                <h2>User Conduct</h2>
                <p>You agree not to use the Service or the Site to:</p>
                <ul>
                    <li>Harvest or collect email addresses or other contact information of other users from the Service or the Site by electronic or other means for the purposes of sending unsolicited emails or other unsolicited communications</li>
                    <li>Not share login credentials with any others users</li>
                    <li>Only log into one device at a time</li>
                    <li>Use the Service or the Site in any unlawful manner or in any other manner that could damage, disable, overburden or impair the Site; upload, post, transmit, share, store or otherwise make available any content that we deem to be harmful, threatening, unlawful, defamatory, infringing, abusive, inflammatory, harassing, vulgar, obscene, fraudulent, invasive of privacy or publicity rights, hateful, or racially, ethnically or otherwise objectionable;</li>
                    <li>Upload, post, transmit, share or otherwise make available any unsolicited or unauthorized advertising, solicitations, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation</li>
                    <li>Upload, post, transmit, share or otherwise make available any material that contains software viruses or any other computer code, files or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment; intimidate or harass another</li>
                    <li>Upload, post, transmit, share, store or otherwise make available content that would constitute, encourage or provide instructions for a criminal offense, violate the rights of any party, or that would otherwise create liability or violate any local, state, national or international law; use or attempt to use another's account, service or system without authorization from the Company, or create a false identity on the Service or the Site</li>
                    <li>Upload, post, transmit, share, store or otherwise make available content that, in the sole judgment of the Company, is objectionable or which restricts or inhibits any other person from using or enjoying the Site, or which may expose the Company or its users to any harm or liability of any type</li>
                </ul>
            </section>
            <section>
                <h2>Service Mobile Services</h2>
                <p>The Service includes certain services that are available via your mobile phone, including:</p>
                <ol type='i'>
                    <li>the ability to upload content to the Service via your mobile phone and</li>
                    <li>the ability to access certain Service features through a mobile application you have downloaded and installed on your mobile phone (collectively the "Mobile Services")</li>
                </ol>
                <p>Your carrier's normal messaging, data and other rates and fees will apply. You should check with your carrier to find out what plans are available and how much they cost. In addition, downloading, installing, or using certain Mobile Services may be prohibited or restricted by your carrier, and not all Mobile Services may work with all carriers or devices. Therefore, you should check with your carrier to find out if the Mobile Services are available for your mobile devices, and what restrictions, if any, may be applicable to your use of such Mobile Services.</p>
                <p>By using the Mobile Services, you agree that we may communicate with you regarding Service and other entities by SMS, MMS, text message or other electronic means to your mobile device and that certain information about your usage of the Mobile Services may be communicated to us. In the event you change or deactivate your mobile telephone number, you agree to promptly update your Service account information to ensure that your messages are not sent to the person that acquires your old number.</p>
             </section>
             <section>
                <h2>Disclaimers</h2>
                <p>The Site and the Service may be temporarily unavailable from time to time for maintenance or other reasons. Company assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorized access to, or alteration of, User communications. The Company is not responsible for any technical malfunction or other problems of any telephone network or service, computer systems, servers or providers, computer or mobile phone equipment, software, failure of email or players on account of technical problems or traffic congestion on the Internet or at any Site or combination thereof, including injury or damage to User's or to any other person's computer, mobile phone, or other hardware or software, related to or resulting from using or downloading materials in connection with the Web and/or in connection with the Service. Under no circumstances will the Company be responsible for any loss or damage resulting from anyone's use of the Site or the Service.</p>
                <p>THE SITE, THE SERVICE (INCLUDING THE MOBILE SERVICES) AND THE SITE CONTENT ARE PROVIDED "AS-IS" AND THE COMPANY DISCLAIMS ANY AND ALL REPRESENTATIONS AND WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT. THE COMPANY CANNOT GUARANTEE AND DOES NOT PROMISE ANY SPECIFIC RESULTS FROM USE OF THE SITE AND/OR THE SERVICE AND/OR ANY PLATFORM APPLICATIONS. THE COMPANY DOES NOT REPRESENT OR WARRANT THAT SOFTWARE, CONTENT OR MATERIALS ON THE SITE OR THE SERVICE ARE ACCURATE, COMPLETE, RELIABLE, CURRENT OR ERROR-FREE OR THAT THE SITE OR SERVICE OR ITS SERVERS ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THEREFORE, YOU SHOULD EXERCISE CAUTION IN THE USE AND DOWNLOADING OF ANY SUCH SOFTWARE, CONTENT OR MATERIALS AND USE INDUSTRY- RECOGNIZED SOFTWARE TO DETECT AND DISINFECT VIRUSES. WITHOUT LIMITING THE FOREGOING, YOU UNDERSTAND AND AGREE THAT YOU DOWNLOAD OR OTHERWISE OBTAIN CONTENT, MATERIAL, DATA OR SOFTWARE FROM OR THROUGH THE SERVICE AND ANY PLATFORM APPLICATIONS AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR YOUR USE THEREOF AND ANY DAMAGES TO YOUR MOBILE DEVICE OR COMPUTER SYSTEM, LOSS OF DATA OR OTHER HARM OF ANY KIND THAT MAY RESULT.</p>
                <p>The Company reserves the right to change any and all content, software and other items used or contained in the Site and any Services and platform applications offered through the Site at any time without notice. Reference to any products, services, processes or other information, by trade name, trademark, manufacturer, supplier or otherwise does not constitute or imply endorsement, sponsorship or recommendation thereof, or any affiliation therewith, by Company.</p>
             </section>
             <section>
                <h2>Limitation on Liability</h2>
                <p>IN NO EVENT WILL THE COMPANY OR ITS DIRECTORS, EMPLOYEES OR AGENTS BE LIABLE TO YOU OR ANY THIRD PERSON FOR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES, INCLUDING FOR ANY LOST PROFITS OR LOST DATA ARISING FROM YOUR USE OF THE SITE OR THE SERVICE, OR ANY OF THE SITE CONTENT OR OTHER MATERIALS ON, ACCESSED THROUGH OR DOWNLOADED FROM THE SITE, EVEN IF THE COMPANY IS AWARE OR HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, THE COMPANY SHALL NOT BE LIABLE TO PARENTS OR GUARDIANS OF UNDERAGE USERS WHO USE THE SITE WITHOUT THE PARENT OR GUARDIAN’S PERMISSION. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, THE COMPANY'S LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO THE COMPANY FOR THE SERVICE, BUT IN NO CASE WILL THE COMPANY'S LIABILITY TO YOU EXCEED $1000. YOU ACKNOWLEDGE THAT IF NO FEES ARE PAID TO THE COMPANY FOR THE SERVICE, YOU SHALL BE LIMITED TO INJUNCTIVE RELIEF ONLY, UNLESS OTHERWISE PERMITTED BY LAW, AND SHALL NOT BE ENTITLED TO DAMAGES OF ANY KIND FROM THE COMPANY, REGARDLESS OF THE CAUSE OF ACTION. CERTAIN PROVINCIAL/STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.</p>
             </section>
             <section>
                <h2>Indemnity</h2>
                <p>You agree to indemnify and hold the Company, its subsidiaries and affiliates, and each of their directors, officers, agents, contractors, partners and employees, harmless from and against any loss, liability, claim, demand, damages, costs and expenses, including reasonable attorney's fees, arising out of or in connection with any content you provide through the Site, your use of the Service or the Site, your conduct in connection with the Service or the Site or with other users of the Service or the Site, or any violation of this Agreement or of any law or the rights of any third party.</p>
             </section>
        </main>
        <Footer/>
    </>
);

export default Terms;
